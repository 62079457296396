import axios, { Method } from "axios";
import { msalInstance } from "..";
import { loginRequest, msalConfig } from "../authConfig";
import { standardResponse } from "./standard-response.model";
import jwt from "jsonwebtoken";
import moment from "moment";
import {
  CANCELLED_REQUEST_MESSAGE,
  DATE_DIFF_TYPE,
  DATE_TIME_FORMAT,
  generateRequestKey,
  getDateDifference,
  TOKEN_EXPIRY_DIFF,
} from "../utils";

const getSilentTokenAndStoreIt = async () => {

  const decodedHeader = decodedJwt();

  const expireDate = new Date(decodedHeader.payload.exp * 1000);
  const currentDate = new Date();

  const expiryMoment = moment(expireDate).format(
    DATE_TIME_FORMAT.REQUEST_DATE_TIME
  );
  const currentMoment = moment(currentDate).format(
    DATE_TIME_FORMAT.REQUEST_DATE_TIME
  );

  const difference = getDateDifference(
    DATE_DIFF_TYPE.SECONDS,
    currentMoment,
    expiryMoment
  );

  if (typeof difference !== "undefined") {
    if (difference <= TOKEN_EXPIRY_DIFF) {
      const token = await msalInstance.acquireTokenSilent({
        scopes: loginRequest.scopes,
      }).catch(() => {
        let authority = msalConfig.auth.authority;
        msalInstance.loginRedirect({
          authority,
          scopes: [],
        });
      });

      if (token) {
        localStorage.setItem("idToken", token.idToken);
      }
    }
  }
};


export const decodeJwt = (token: string): any | null =>
  jwt.decode(token, { complete: true });

export const decodedJwt = (): any | null =>
  decodeJwt(localStorage.getItem("idToken") ?? "");

export const getBearerToken = async () => {
  // const account = msalInstance.getActiveAccount();

  // if (!account) {
  //   throw Error(
  //     "No active account! Verify a user has been signed in and setActiveAccount has been called."
  //   );
  // }

  // await getSilentTokenAndStoreIt();

  return `Bearer ${localStorage.getItem("idToken")}`;
};

export const returnPromise = async (
  requestedURL: string,
  httpMethod: Method,
  data?: any,
  isZip?: boolean
) => {
  try {
    const res = await BaseService({
      url: requestedURL,
      method: httpMethod,
      ...(data ? { data } : null),
      ...(isZip ? { responseType: 'blob' } : null)
    });
    return standardResponse(res);
  } catch (e) {
    return Promise.reject(e);
  }
};

const ongoingRequests = new Map();

const BaseService = axios.create({
  baseURL: process.env.REACT_APP_API_URL, // url = base url + request url
});

BaseService.interceptors.request.use(
  async (config: any) => {
    const isFormData = config.data && config.data.isFormData === true;
    try {

      const requestKey = generateRequestKey(config);

      if (ongoingRequests.has(requestKey)) {
        ongoingRequests.get(requestKey).cancel(CANCELLED_REQUEST_MESSAGE);
      }

      config.headers["Authorization"] = await getBearerToken();
      config.headers["Access-Control-Allow-Origin"] = "*";
      config.headers["X-User"] = "";
      const decodedHeader = decodedJwt();
      if (decodedHeader && decodedHeader.payload && decodedHeader.payload.sub) {
        config.headers["X-User"] = window.btoa(decodedHeader.payload.sub);
      }
      if (isFormData) {
        config.headers["Content-Type"] = "multipart/form-data";
      }      
       const cancelTokenSource = axios.CancelToken.source();
       config.cancelToken = cancelTokenSource.token;
 
       ongoingRequests.set(requestKey, cancelTokenSource);
    } catch (error) {
      //window.location.reload();
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// Response interceptors Customize based on your need
BaseService.interceptors.response.use(
  (response) => {
    const requestKey = generateRequestKey(response.config);
    ongoingRequests.delete(requestKey);
    return response;
  },
  async (error) => {
    const requestKey = generateRequestKey(error.config);
    ongoingRequests.delete(requestKey);
    switch (error.response && error.response.status) {
      case 401:
        //localStorage.clear();
        // msalInstance.logoutRedirect({
        //   postLogoutRedirectUri: "/",
        // });
        break;
      // localStorage.removeItem("idToken");
      // getSilentTokenAndStoreIt();

      // return BaseService(originalConfig);
      // Authorization Failed Response can add other status codes here to manage error Logging
      case 403:
        break;
      default:
        break;
    }
    if(error.message && error.message===CANCELLED_REQUEST_MESSAGE) return;
    return Promise.reject(error.response);
  }
);

export default BaseService;
